 import ExploreHapiMeetsBg from "../../assets/img/ExploreHapiMeetsBg.svg";
import {
  Box, Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Input,
  Text,
  Textarea,
  useBreakpointValue
} from "@chakra-ui/react";
import Header from "../Header/Header";
import Footer from "../Footer";
 import API from "../../services/api";
 import {useForm} from "react-hook-form";
 import {useState} from "react";

 interface FormValues {
  name: string;
  email: string;
  text: string;
 }

export default function SupportForm() {
  // const isMobile = useBreakpointValue({ base: true, md: false });

  /*const onSendClick = () => {
    API.emails.contactRequest({
      CompanyName:'HapiMeets Support',
      Email:'ascarbek@gmail.com',
      Message: ''
    })
  }*/

  const [sending, setSending] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()

  const onSubmit = handleSubmit(async (data) => {
    setSending(true);
    await API.emails.contactRequest({
      email: data.email,
      message: data.text,
      name: data.name,
    });
    setSending(false);
  })

  return (
    <div>
      <Header/>
      <Box
        bgImage={ExploreHapiMeetsBg}
        bgRepeat="no-repeat"
        className="container-wrapper"
        pr={{base: "24px", md: "70px", lg: "144px"}}
        pl={{base: "24px", lg: "144px", md: "70px"}}
        pt={{base: "40px"}}
        minHeight={{base: 'calc(100vh - 100px - 77px)', md: 'calc(100vh - 100px - 126px)'}}
      >
        <Box
          bgRepeat="no-repeat"
          bgPosition={{base: "center", md: "bottom"}}
        >
          <Text
            className="h1"
            pb="24px"
            w={{base: "auto", sm: "374px", md: "auto"}}
          >
            {'Contact Support'}
          </Text>
        </Box>

        <Card
          borderRadius="25px"
          pl="20px"
          pr={'20px'}
          className="explore-card"
        >
          <Box pb={{base: "10px"}} w={'100%'}>
            <CardHeader>
              <Text className={"sub2"} pt="20px">
                Enter request details
              </Text>
            </CardHeader>
            <CardBody pt="0">
              <form onSubmit={onSubmit}>
                <Flex direction={'column'} gap={'4'}>
                  <Flex direction={'column'} gap={'1'}>
                    <Text>Name:</Text>
                    <Input {...register("name")} placeholder="Enter your name" disabled={sending} />
                  </Flex>
                  <Flex direction={'column'} gap={'1'}>
                    <Text>Email:</Text>
                    <Input {...register("email")} placeholder="Enter you email" disabled={sending} />
                  </Flex>
                  <Flex direction={'column'} gap={'1'}>
                    <Text>Message:</Text>
                    <Textarea {...register("text")} rows={5} placeholder={'Enter message'} disabled={sending} />
                  </Flex>
                  <Flex justifyContent={'center'}>
                    <Button type={'submit'} className="button1" disabled={sending}>
                      Send Request
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </CardBody>
          </Box>
        </Card>
      </Box>
      <Footer/>
    </div>
  )
}