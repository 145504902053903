import axios from 'axios'
import { apiUrl } from './config'


export const axiosAPIInstance = axios.create({
  baseURL: apiUrl,
})

const API = {
  emails: {
    contactRequest: (x: {
      name: string,
      company?: string,
      phone?: string,
      email: string,
      message: string
    }) => {
      return axiosAPIInstance.post(`/api/v1/mobile/configuration/email_relay/`, x);
    }
  },

  testing: {
    alwaysSucceed: (timeTakenMs: number) => {
      return new Promise((resolve: (value: string) => void) => {
        setTimeout(() => {
          resolve("Task succeded succesfully.")
        }, timeTakenMs)
      })
    },
    alwaysFail: (timeTakenMs: number) => {
      return new Promise((resolve: (value: undefined) => void, reject: (value: string) => void) => {
        setTimeout(() => {
          reject("Task failed succesfully.")
        }, timeTakenMs)
      })
    },
  },
}
export default API;
