export const myranUrl = `${process.env.REACT_APP_HAPIMEETS_URL}`
const search = '/api'
const n = 2
export const apiBase = process.env.REACT_APP_API_BASE?.replace(
  RegExp('^(?:.*?api){' + n + '}'),
  function (x) {
    return x.replace(RegExp(search + '$'), '')
  },
)
export const apiUrl = `${process.env.REACT_APP_API_BASE || `https://backend.hapimeets.com`}`
